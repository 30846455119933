export const excludeDeletedAlgoliaSkus = (
	afterAlgoliaSearchSkus = [],
	allProductSkus = [],
) => {
	const algoliaSkusSet = new Set(afterAlgoliaSearchSkus);
	const allProductSkusSet = new Set(allProductSkus);

	algoliaSkusSet.forEach(productSku => {
		if (!allProductSkusSet.has(productSku)) {
			algoliaSkusSet.delete(productSku);
		}
	});

	return Array.from(algoliaSkusSet);
};
