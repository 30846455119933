import insightEvent from './insightEvent';
import addedToCartObjectIDsAfterSearchStorage from './addedToCartObjectIDsAfterSearchStorage';

const addedToCartObjectIDsAfterSearch = (queryID, productCode) => {
	insightEvent('addedToCartObjectIDsAfterSearch', {
		queryID,
		objectIDs: [productCode],
	});

	addedToCartObjectIDsAfterSearchStorage(productCode);
};

export default addedToCartObjectIDsAfterSearch;
