import purchasedObjectIDs from './purchasedObjectIDs';
import purchasedObjectIDsAfterSearch from './purchasedObjectIDsAfterSearch';
import { excludeAlgoliaSkus } from '../../../utils/excludeAlgoliaSkus';
import { excludeDeletedAlgoliaSkus } from '../../../utils/excludeDeletedAlgoliaSkus';
import { removeProductsAfterAlgoliaSearchStorage } from './addedToCartObjectIDsAfterSearchStorage';

const getPurchasedProductSkus = () => window?.dataLayer[0]?.order?.skuID;

const getAfterAlgoliaSearchSkus = () => {
	const storedSkus = localStorage.getItem('productsAfterAlgoliaSearch');
	return storedSkus ? JSON.parse(storedSkus) : null;
};

const processNonAlgoliaSkus = (
	purchasedProductSkus,
	afterAlgoliaSearchSkus,
) => {
	const otherPurchasedProducts = excludeAlgoliaSkus(
		afterAlgoliaSearchSkus,
		purchasedProductSkus,
	);

	if (otherPurchasedProducts.length > 0) {
		purchasedObjectIDs(otherPurchasedProducts);
	}
};

const processAlgoliaSkus = (purchasedProductSkus, afterAlgoliaSearchSkus) => {
	const algoliaPurchasedProducts = excludeDeletedAlgoliaSkus(
		afterAlgoliaSearchSkus,
		purchasedProductSkus,
	);

	if (algoliaPurchasedProducts.length > 0) {
		if (algoliaPurchasedProducts.length > 20) {
			algoliaPurchasedProducts.length = 20;
		}

		purchasedObjectIDsAfterSearch(algoliaPurchasedProducts);
	}
};

const handlePurchasedObject = () => {
	const purchasedProductsSkus = getPurchasedProductSkus();
	const afterAlgoliaSearchSkus = getAfterAlgoliaSearchSkus();

	if (!purchasedProductsSkus) {
		removeProductsAfterAlgoliaSearchStorage();
		return;
	}

	if (!afterAlgoliaSearchSkus) {
		purchasedObjectIDs(purchasedProductsSkus);
	} else {
		processNonAlgoliaSkus(purchasedProductsSkus, afterAlgoliaSearchSkus);
		processAlgoliaSkus(purchasedProductsSkus, afterAlgoliaSearchSkus);
	}

	removeProductsAfterAlgoliaSearchStorage();
};

export default handlePurchasedObject;
