const addedToCartObjectIDsAfterSearchStorage = productCode => {
	const productsAfterAlgoliaSearch = localStorage.getItem(
		'productsAfterAlgoliaSearch',
	);

	if (!productsAfterAlgoliaSearch) {
		setProductsAfterAlgoliaSearchStorage([productCode]);
	} else {
		let storedProducts;

		try {
			storedProducts = new Set(JSON.parse(productsAfterAlgoliaSearch));
		} catch (e) {
			storedProducts = new Set();
		}

		storedProducts.add(productCode);
		setProductsAfterAlgoliaSearchStorage([...storedProducts]);
	}
};

const setProductsAfterAlgoliaSearchStorage = item => {
	localStorage.setItem('productsAfterAlgoliaSearch', JSON.stringify(item));
};

export const removeProductsAfterAlgoliaSearchStorage = () => {
	localStorage.removeItem('productsAfterAlgoliaSearch');
};

export default addedToCartObjectIDsAfterSearchStorage;
