import addedToCartObjectIDsAfterSearch from './addedToCartObjectIDsAfterSearch';
import addedToCartObjectIDs from './addedToCartObjectIDs';
import getQueryIdFromUrl from '../../../utils/getQueryIdFromUrl';

const handleAddToCartInsight = productCode => {
	const queryID = getQueryIdFromUrl();
	if (queryID) {
		addedToCartObjectIDsAfterSearch(queryID, productCode);
	} else {
		addedToCartObjectIDs(productCode);
	}
};

const addToCartSubscribe = () => {
	window?.od?.events?.subscribe('addToCart_Submitting', data => {
		window?.od?.events?.subscribe(
			'addToCart_Submitted',
			handleAddToCartInsight(data?.productCode),
		);
	});
};

export default addToCartSubscribe;
